<i18n>
{
    "ar": {

        "sections": {
            "1": {
                "title": "شريكك الرقمي لبناء هويتك الإلكترونية",
                "subtitle": "نعمل على برمجة وتطوير خدمات ومنصات توفر حلولاً لأصحاب الأعمال التجارية والتسويق لعملائنا من خلال حملات دعاية وإعلانية مبتكرة تضمن تحقيق أهدافهم."
            },
            "about": {
                "title": "معلومات عن الشركة",
                "subtitle": "معلومات وتفاصيل عن الشركة وآلية عملها وأهدافها",
                "row1": {
                    "title": "من نحن وماذا نفعل؟",
                    "content": "منذ 2013 ونحن نخدمكم بإتقان وإخلاص، والأن وبعد 10 سنوات يسرنا أن نعلن أننا قد أصبحنا مؤسسة رسمية مسجلة. نعمل علي دعم وتطوير اﻷعمال اﻹلكترونية وتسهيل آلية عملها وإحداث طفرة في أسلوب استخدام اﻷعمال للتكنولوجيا الحديثة كما تقدم الشركة خدمات ومنصات تساعد وتحل مشاكل العاملين بأي صناعة من صناعات اﻷعمال في الشرق اﻷوسط والوطن العربي."
                },
                "row2": {
                    "title": "نطور برمجيات وأنظمة مخصصة ومتطورة!",
                    "content": "يستخدم فريق عمل شركة Nova أحدث تقنيات البرمجة والتصميم لتطوير المواقع واﻷنظمة وبوتات الانترنت Bots وكذلك برامج الحاسوب مما يمكننا من تنفيذ اﻷفكار واﻹبتكارات بشكل مخصص وإطلاق منصات حديثة، سريعة، وقوية تشمل مميزات غير مسبوقة باﻷنظمة اﻷخرى في الشرق اﻷوسط والوطن العربي!"
                }
            },
            "contact": {
                "subtitle": "اترك لنا رسالة وسوف نقوم بالرد عليك بأسرع وقت ممكن"
            },
            "timeline": {
                "title": "الخط الزمني للشركة",
                "subtitle": "شريط زمني يضم الأحداث والتواريخ الهامة في تاريخ شركة Nova"
            },
            "products": {
                "title": "مشاريعنا",
                "subtitle": "المنصات والخدمات اﻹلكترونية التابعة لشركتنا والتي نعمل على تطويرها وإدراتها"
            },
            "industries": {
                "title": "مجالات عملنا",
                "subtitle": "المجالات والصناعات التي نعمل حالياً على تطوير برمجيات متقدمة لدعمها وحل مشكلاتها",
                "cols": [
                    {
                        "headline": "التصميم والبرمجة",
                        "description": "نعمل على تطوير وإدارة المواقع الالكتونية ونضمن توافقها مع جميع الاجهزة ومتطلبات العملاء مما يعطي قوة أكبر لمجال عملك  لضمان تحقيق متطلباتك وأهدافك بكل دقة واحترافية."
                    },
                    {
                        "headline": "الدعاية والاعلان",
                        "description": "تقدم الشركة خدمات الدعاية والإعلان والتسويق الالكتروني، حيث تساعد الشركات والمؤسسات في الترويج لخدماتها ومنتجاتها وزيادة الوعي بالعلامة التجارية. وتقدم هذه الخدمات من خلال تصميم الإعلانات الإبداعية والتسويق الإلكتروني وتحليل البيانات وتطوير الحملات الإعلانية."
                    },
                    {
                        "headline": "الاستضافة والسيرفرات",
                        "description": "نقدم خدمات استضافة المواقع والسيرفرات بأسعار تنافسية، ويتميز بالموثوقية والأمان والسرعة، نوفر لك خادم فى بى اس قوى لينكس وويندوز ويتحمل ملايين الزوار وأيضًا الدعم الفني والصيانة والحماية الأمنية للمواقع والتطبيقات."
                    }
                ]
            },
            "actions": {
                "contact": "تواصل معنا",
                "about": "معرفة المزيد عنّا",
                "products": "مشاريعنا"
            }
        },
        "timeline": [
            {
                "title": "تأسيس شركة Nova لتطوير البرمجيات والتسويق",
                "content": "تأسيس شركة Nova لتطوير البرمجيات والحلول التقنية واﻷنظمة والدعايا والاعلان والتسويق الالكتروني."
            },
            {
                "date": "قريباً",
                "title": "إطلاق أول منصة تابعة للشركة",
                "content": "إطلاق أول نظام ومنصة تابعة لشركة Nova في مجال التجارة اﻹلكترونية بهدف تقديم حلول أفضل وأكثر تطوراً وسهولةً وحل المشكلات الموجودة بالسوق حالياً."
            }
        ],
        
        "contact": {
            "subheader": "أو تواصل معنا عبر الوسائل اﻵتية:",
            "email": "البريد اﻹلكتروني",
            "facebook": "صفحة فيسبوك",
            "website": "رقم الهاتف"
        }
    },
    "en": {
        "sections": {
            "1": {
                "title": "Your digital partner for building your online identity",
                "subtitle": "We work on programming and developing services and platforms that provide solutions for business owners and marketing for our clients through innovative advertising campaigns to ensure the achievement of their goals."
            },
            "about": {
                "title": "About Us",
                "subtitle": "Who we are and What are our goals.",
                "row1": {
                    "title": "Who we are, What we do, and What are our goals?",
                    "content": "Since 2013, we have been serving you with excellence and dedication, and now after 10 years, we are pleased to announce that we have become an officially registered institution. We work on supporting and developing e-businesses, facilitating their workflow, and revolutionizing the way businesses use modern technology. The company provides services and platforms that help and solve problems for workers in any industry in the Middle East and the Arab world."
                },
                "row2": {   
                    "title": "We develop custom and advanced software and systems!",
                    "content": "The Nova team uses the latest programming and design technologies to develop websites, systems, internet bots, and computer programs, allowing us to implement custom ideas and innovations and launch modern, fast, and powerful platforms with unprecedented features compared to other systems in the Middle East and the Arab world"
                }
            },
            "contact": {
                "subtitle": "Leave us a message and we'll reply as soon as possible"
            },
            "timeline": {
                "title": "Timeline of our history",
                "subtitle": "the most important dates and events in Nova's history"
            },
            "products": {
                "title": "Our Products & Projects",
                "subtitle": "Platforms, Services and Solutions that are developed and managed by Nova"
            },
            "industries": {
                "title": "Industries We Develop",
                "subtitle": "Industries that we are currently working on developing it and providing software solutions to solve their problems.",
                "cols": [
                    {
                        "headline": "Design and programming",
                        "description": "We work on developing and managing electronic websites, ensuring their compatibility with all devices and customer requirements, giving your business a greater strength to achieve your requirements and goals with precision and professionalism."
                    },
                    {
                        "headline": "Advertising and marketing",
                        "description": "The company offers advertising, marketing, and digital marketing services, helping companies and organizations promote their services and products and increase brand awareness. These services are provided through creative advertising design, digital marketing, data analysis, and advertising campaign development."
                    },
                    {
                        "headline": "Hosting and servers",
                        "description": "We provide web hosting and server services at competitive prices, characterized by reliability, security, and speed. We offer a powerful Linux and Windows VPS server that can handle millions of visitors, as well as technical support, maintenance, and security for websites and applications."
                    }
                ]
            },
            "actions": {
                "contact": "Contact Us",
                "about": "More About Us",
                "products": "Our Products"
            }
        },
        "timeline": [
            {
                "title": "Nova company was established to develop software and marketing",
                "content": "The establishment of Nova company for software development, technical solutions, systems, advertising, and electronic marketing."
            },
            {
                "date": "Soon",
                "title": "Launching the first platform owned by the company",
                "content": "Launching Nova's first e-commerce platform and system aimed at providing better, more advanced, and easier solutions to address current market challenges."
            }
        ],
        "contact": {
            "subheader": "or contact us via:",
            "email": "Email Address",
            "facebook": "Facebook Page",
            "website": "Phone Number"
        }
    }
}
</i18n>
<template>
    <div class="page page-sections">
        <section id="headerSection" data-section="home" class="header-section dark-gradient fullpage-section">
            <animated-background />
            <v-container v-if="!loading" class="fill-height pt-12" :class="{'align-center': phoneOnly}">
                <v-slide-y-transition appear >
                <h1 class="strong-text--text">
                    <animate-text :duration="1500" :stop-after="0" :delay="500" :text="$t('sections.1.title')" />
                    <!-- <span class="secondary--text font-weight-medium">Nova</span> -->
                </h1>
                </v-slide-y-transition>
                <v-slide-x-transition appear>
                <h2 class="text--text mt-5">
                    <animate-text @done="showSection1Actions = true" :duration="2500" :delay="2000" :text="$t('sections.1.subtitle')"></animate-text>
                    <!-- <div>Information Technology & Electronic Business Company</div> -->
                </h2>
                </v-slide-x-transition>
                <div class="actions" :class="{'fade-up-off': !showSection1Actions, 'fade-up-on': showSection1Actions}">
                    <!-- <v-btn @click="navigateToLink('#productsSection')" color="strong-text" tile :large="pcOnly" depressed outlined class="me-6">{{$t('sections.actions.products')}}</v-btn> -->
                    <v-btn @click="navigateToLink('#aboutSection')" color="strong-text" tile :large="pcOnly" depressed outlined class="me-6">{{$t('sections.actions.about')}}</v-btn>
                    <v-btn @click="navigateToLink('#contactSection')" color="secondary" tile :large="pcOnly" outlined>{{$t('sections.actions.contact')}}</v-btn>
                </div>
            </v-container>

            <div class="section-scroll-icon">
                <scroll-icon @click="scrollToSection(2)"/>
            </div>
        </section>
        <e-section id="aboutSection" data-section="about" :headline="$t('sections.about.title')" :subtitle="$t('sections.about.subtitle')" light divider>
            <v-row>
                <v-col :data-aos="pcOnly ? (isRtl ? 'fade-left' : 'fade-right') : 'fade-up'" data-aos-duration="800" :cols="pcOnly ? 5 : 12" class="">
                    <v-img src="/img/vector1.png" contain width="100%" max-height="320"></v-img>
                </v-col>
                <v-spacer></v-spacer>
                <v-col :data-aos="pcOnly ? (isRtl ? 'fade-right' : 'fade-left') : 'fade-up'" data-aos-duration="800" class="mt-6 mt-lg-0" :cols="pcOnly ? 6 : 12">
                    <h1 class="headline">{{$t('sections.about.row1.title')}}</h1>
                    <p class="medium-text text-light--text mt-6 mt-lg-8" :class="{'text-center': phoneOnly}">
                        {{$t('sections.about.row1.content')}}
                    </p>
                    <div class="actions">
                        <v-btn @click="navigateToLink('#industriesSection')" tile large depressed color="primary">{{$t('sections.industries.title')}}</v-btn>
                        <v-btn @click="navigateToLink('#timelineSection')" tile large depressed color="primary" outlined class="ms-lg-4 mt-3">{{$t('sections.timeline.title')}}</v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-top: 3.5rem" class=" flex-row-reverse">

                <v-col :data-aos="pcOnly ? (isRtl ? 'fade-right' : 'fade-left') : 'fade-up'" data-aos-duration="800" :cols="pcOnly ? 5 : 12" class="">
                    <v-img src="/img/vector2.png" contain width="100%" max-height="320"></v-img>
                </v-col>
                <v-spacer></v-spacer>
                <v-col :data-aos="pcOnly ? (isRtl ? 'fade-left' : 'fade-right') : 'fade-up'" data-aos-duration="800" class="mt-6 mt-lg-0" :cols="pcOnly ? 6 : 12">
                    <h1 class="headline">{{$t('sections.about.row2.title')}}</h1>
                    <p class="medium-text text-light--text mt-6 mt-lg-8" :class="{'text-center': phoneOnly}">
                        {{$t('sections.about.row2.content')}}
                    </p>

                    <div class="actions">
                        <v-btn @click="navigateToLink('#productsSection')" tile large depressed color="primary">{{$t('sections.products.title')}}</v-btn>
                        <!-- <v-btn @click="navigateToLink('#timelineSection')" tile large depressed color="primary" outlined class="ms-4">{{$t('sections.timeline.title')}}</v-btn> -->
                    </div>
                </v-col>
            </v-row>
        </e-section>

        <cols-section id="industriesSection" :headline="$t('sections.industries.title')" :subtitle="$t('sections.industries.subtitle')" :cols="industriesCols">

        </cols-section>


        <e-section id="productsSection" data-section="products" background="light" :headline="$t('sections.products.title')" :subtitle="$t('sections.products.subtitle')" light>
            <v-row class="flex-wrap justify-center">
                <v-col v-for="(project, i) in projects" :key="i" :cols="pcOnly ? 4 : 12">
                    <v-card v-ripple="project.href ? {class: 'soft-ripple'} : false" data-aos="fade-up" :data-aos-duration="800" :data-aos-delay="400*i" class="card-shadow fill-height" :href="project.href ? project.href : ''" :target="project.href ? '_blank' : ''">
                        <v-img :src="project.image" cover height="250" />
                        <div class="py-1 px-3">
                            <v-card-title class="primary--text mb-1">{{project.title}}</v-card-title>
                            <v-card-subtitle>{{project.subtitle}}</v-card-subtitle>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </e-section>

        <e-section id="timelineSection" data-section="timeline" divider :headline="$t('sections.timeline.title')" :subtitle="$t('sections.timeline.subtitle')" light>
            <timeline data-aos="fade-up" :data-aos-duration="800" :data="timeline" />
        </e-section>

        <e-section id="contactSection" data-section="contact" :headline="$t('links.contact')" :subtitle="$t('sections.contact.subtitle')" light>
            <v-row class="justify-center px-lg-12">
                <v-col :cols="pcOnly ? 6 : 12" class="ps-lg-12">
                    <contact-form data-once :data-aos="'fade'" :data-aos-duration="800" />
                </v-col>
                <v-col v-if="pcOnly" cols="1" class="text-center">
                    <v-divider vertical></v-divider>
                </v-col>
                <v-col :cols="pcOnly ? 3 : 12">
                    <div :data-aos="'fade'" :data-aos-duration="800">
                        <v-subheader>
                            {{$t('contact.subheader')}}
                        </v-subheader>
                        <v-list two-line>
                            <v-list-item v-for="item in contactItems" :key="item.name" :target="item.href ? '_blank' : ''" :href="item.href ? item.href : (item.name === 'email' ? `mailto:${item.value}` : '')">
                                <v-list-item-icon>
                                    <v-icon :color="item.color">{{item.icon}}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="mb-2">{{$t('contact.' + item.name)}}</v-list-item-subtitle>
                                    <v-list-item-title>{{item.value}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                    </div>
                </v-col>
            </v-row>
        </e-section>


        <!-- <e-section headline="فريق العمل" subtitle="فريق عمل Nova في الوقت الحالي" light>
            <v-row>
                <v-col v-for="member in teamMembers" cols="4" :key="member.name">
                </v-col>
            </v-row>
        </e-section> -->

        <e-footer />

        <scroll-to-top />
    </div>
</template>

<script>
// @ is an alias to /src
import 'particles.js';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


import ScrollToTop from '@/components/custom/scroll-to-top'
import AnimateText from '@/components/custom/animate-text'
import ScrollIcon from '@/components/custom/scroll-icon'
import ContactForm from '@/components/custom/contact-form'
import AnimatedBackground from '@/components/custom/animated-background'
import ColsSection from '@/components/default/cols-section'
import ESection from '@/components/default/e-section'
import Timeline from '@/components/default/timeline'
import EFooter from '@/components/main/e-footer'


import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    name: "Home",
    components: {
        ScrollToTop,
        ContactForm,
        AnimateText,
        Timeline,
        ColsSection,
        ESection,
        ScrollIcon,
        EFooter,
        AnimatedBackground
    },
    data(){
        return {
            showSection1Actions: false,
            currentScrollTop: 0,
            
            contactItems: [
                {name: 'email', icon: 'mdi-email', value: 'info@Novaeg.net', color: 'primary'},
                {name: 'facebook', icon: 'mdi-facebook', value: '@novaeg', color: 'blue darken-1', href: 'https://facebook.com/Nova.net'},
                {name: 'website', icon: 'mdi-phone', value: '01020330051', color: 'grey', href: ''},
            ],
            projects: [
                {
                    image: '/img/dndnha.png',
                    title: "Dndnha",
                    subtitle: "Dndnha is an Arabic music website in the Middle East that allows easy listening and downloading of mp3 songs with numerous features and fast, simple, and attractive design, as well as lightning-fast archiving"
                },
                {
                    image: '/img/novavps.png',
                    title: "Nova VPS",
                    subtitle: "NovaVPS is a web hosting company that provides Virtual Private Servers (VPS) for businesses and individuals. With NovaVPS, you can have a dedicated virtual server to host your website or application, etc"
                },
                {
                    image: '/img/mmostore.png',
                    title: "MMOstore",
                    subtitle: "A programming and design services website specialized in the field of gaming, managing and selling game servers, designing graphics, and everything related to online gaming."
                },
            ],
            teamMembers: [
                {name: 'Ebrahim Maher', job: 'Founder, CEO & Developer', avatar: '/img/team/ebrahim-maher.jpg', description: 'Founder and CEO of Nova company '}
            ],
        }
    },
    computed: {
        industriesCols(){
            return [
               {color: 'blue', icon: 'mdi-code-braces', headline: this.$t('sections.industries.cols.0.headline'), description: this.$t('sections.industries.cols.0.description')},
               {color: 'deep-orange', icon: 'mdi-camera-front', headline: this.$t('sections.industries.cols.1.headline'), description: this.$t('sections.industries.cols.1.description')},
               {color: 'teal', icon: 'mdi-server-security', headline: this.$t('sections.industries.cols.2.headline'), description: this.$t('sections.industries.cols.2.description')},
            ];
        },
        timeline(){

            return [
                {date: '27-12-2022', title: this.$t('timeline.0.title'), content: this.$t('timeline.0.content')},
                {date: this.$t('timeline.1.date'), title: this.$t('timeline.1.title'), content: this.$t('timeline.1.content')}
            ];
        },
        ...GlobalComputed
    },
    methods: {
        scrollToSection(n){
            let i = n-1,
                element = document.querySelectorAll('.page-sections section')[i];
            if (element){
                this.scrollToElement(element);
            }
        },
        scrollObserver(){
            window.addEventListener('scroll', ()=>{
                this.currentScrollTop = document.documentElement.scrollTop;
            })
        },
        handleScrollChange(scrollTop){
            const sections = document.querySelectorAll('.page-sections section');
            sections.forEach(section => {
                let offsetTop = section.offsetTop - this.navbarHeight, // decrease navbarHeight
                    offsetBottom = offsetTop + section.offsetHeight;

                if (scrollTop >= offsetTop && scrollTop < offsetBottom){
                    let sectionName = section.getAttribute('data-section');
                    if(sectionName){
                        this.$store.commit('SET_ACTIVE_SECTION', sectionName)
                    }
                    // section is visible...
                    if (section.getAttribute('data-theme') === 'light'){
                        
                        if (!this.lightNavbar){
                            this.$store.commit('SET_LIGHT_NAVBAR', true);
                        }
                    }else{
                        if (this.lightNavbar){
                            this.$store.commit('SET_LIGHT_NAVBAR', false);
                        }
                    }
                }
            })

        },

        ...GlobalMethods
    },
    watch: {
        currentScrollTop(top){
            this.handleScrollChange(top);
        }
    },

    mounted(){
        this.scrollObserver();
        AOS.init({container: document.documentElement, once: true, easing: 'ease'});
    }
};
</script>
<style lang="scss" scoped>
    section{

        .container{
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: start;
            flex-direction: column;

            &, *{
                z-index: 1;
            }
        }
        &.header-section{
            color: white;
        }
        h1{
            font-size: 4rem;
            font-weight: 300;

            @media(max-width: 960px){
                text-align: center;
                font-size: 1.7rem;
                line-height: 2.4rem;
                font-weight: 400;
            }
        }
        h2{
            font-size: 2.75rem;
            word-break: break-word!important;
            font-weight: 200;

            @media(max-width: 960px){
                text-align: center;
                font-size: 1.3rem;
                font-weight: 300;
            }
        }
        .actions{
            margin-top: 3rem;
            @media(max-width: 960px){
                margin: 2rem 0;
                width: 100%;
                text-align: center;
                justify-content: center;
            }
            .v-btn{
                @media(min-width: 960px){
                    min-width: 130px;
                    min-height: 45px;
                }
            }
        }
        .section-scroll-icon{
            z-index: 2;
            position: absolute;
            bottom: 8%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
</style>